import React from 'react'
import Navbar from '../Navbar'
import Landing from './components'

export default props => {
  return (
    <>
      <Navbar />
      <Landing />
    </>
  )
}
