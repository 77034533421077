import React from 'react'
import Link from '../Link'
import Icon from '../Icon'
import { Wrapper, Nav, Banner, Logo, Name, Right } from './components'

const Navbar = () => {
  return (
    <Wrapper>
      <Nav>
        <Link to="/">
          <Banner>
            <Logo />
            <Name>Lapukron</Name>
          </Banner>
        </Link>
        <Link to="/">
          <Right>
            Sign In <Icon weight="l" name="sign-in-alt" />
          </Right>
        </Link>
      </Nav>
    </Wrapper>
  )
}

export default Navbar
