import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Icon from '../Icon'

const OutlinedInputAdorment = ({ label, value, icon, error, message }) => {
  return (
    <FormControl error={error}>
      <TextField
        variant="outlined"
        label={label}
        value={value}
        InputProps={{
          endAdornment: <InputAdornment position="end">{icon}</InputAdornment>
        }}
        error={error}
      />
      {error && <FormHelperText>{message}</FormHelperText>}
    </FormControl>
  )
}

OutlinedInputAdorment.defaultProps = {
  label: 'Placeholder',
  value: '',
  icon: <Icon weight="b" name="font-awesome" />,
  error: false,
  message: 'Message'
}

export { OutlinedInputAdorment }
