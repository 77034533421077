import React, { useState, useEffect } from 'react'
import moment from 'moment'
import './Landing.css'

const getCountdownObj = target => {
  const now = moment()
  return {
    days: target.diff(now, 'days'),
    hours: moment.duration(target.diff(now)).hours(),
    minutes: moment.duration(target.diff(now)).minutes(),
    seconds: moment.duration(target.diff(now)).seconds()
  }
}

const Landing = props => {
  const target = moment('2029-07-13')

  const [countdown, setCountdown] = useState(getCountdownObj(target))

  useEffect(() => {
    const intervalId = setInterval(
      () => setCountdown(getCountdownObj(target)),
      1000
    )

    return () => clearInterval(intervalId)
  }, [])

  return (
    <div className="Landing">
      <div className="Landing-content">
        <h1 className="Landing-content-header">Coming Soon</h1>
        <div className="Landing-content-time">
          <div className="Digit">
            <div className="Digit-header">Days</div> {countdown.days}
          </div>
          <div className="Digit">
            <div className="Digit-header">Hours</div> {countdown.hours}
          </div>
          <div className="Digit">
            <div className="Digit-header">Minutes</div> {countdown.minutes}
          </div>
          <div className="Digit">
            <div className="Digit-header">Seconds</div> {countdown.seconds}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Landing
