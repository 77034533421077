import styled from 'styled-components'
import logo from '../../icon.png'

const Wrapper = styled.nav`
  height: 70px;
  background-color: #444;
  padding: 0 15%;
  color: white;
  position: fixed;
  width: 100%;
  z-index: 999;
`
const Nav = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
`
const Banner = styled.div`
  display: flex;
  align-items: center;
`
const Logo = styled.img.attrs({ src: logo, alt: 'Lapukron Logo' })`
  height: 32px;
  width: 32px;
  margin-right: 1rem;
`
const Name = styled.h1`
  font-size: 2rem;
  &:hover {
    color: lightgrey;
  }
`

const Right = styled.div``

export { Wrapper, Nav, Banner, Logo, Name, Right }
