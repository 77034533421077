import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Landing from './components/Landing'
import SignIn from './components/SignIn'
import GlobalStyle from './GlobalStyle'

const App = props => {
  return (
    <Router>
      <GlobalStyle />
      <Route path="/" exact component={Landing} />
      <Route path="/SignIn" component={SignIn} />
    </Router>
  )
}

export default App
