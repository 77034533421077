import React from 'react'
import Icon from '../Icon'
import { OutlinedInputAdorment } from '../Input'
import { Paper } from './components'

const SignIn = props => {
  return (
    <Paper>
      <OutlinedInputAdorment
        label="Username"
        value=""
        icon={<Icon weight="s" name="user" />}
      />
      <OutlinedInputAdorment
        label="Password"
        value=""
        icon={<Icon weight="s" name="key" />}
      />
    </Paper>
  )
}

export default SignIn
